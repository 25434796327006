import React from "react";
import Slider from "react-slick";
import _ from "lodash";

const CallOut = (props) => {
  const settings = {
    accessibility: false,
    draggable: false,
    swipe: false,
    arrows: false,
    dots: false,
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    autoplay: true,
  };

  const calloutMessages = [
    {
      key: "beacon",
      text: "Johnson and Johnson has provided notice of an update to its 340B policy for DSH Covered Entities.",
    },
    {
      key: "astrazeneca",
      text: "AstraZeneca has provided notice of an update to its 340B Contract Pharmacy policy.",
    },
    {
      key: "abbvie",
      text: "AbbVie has provided notice of an update to its 340B Contract Pharmacy policy.",
    },
    {
      key: "bms",
      text: "BMS has made an update to its 340B practice",
    },
    {
      key: "glaxosmithkline",
      text: "GlaxoSmithKline has provided notice of an update to its 340B Contract Pharmacy policy.",
    },
    {
      key: "amgen",
      text: "Amgen has provided notice of an update to its 340B Contract Pharmacy policy.",
    },
    {
      key: "vertex",
      text: "Vertex has provided notice of an update to its 340B Contract Pharmacy policy.",
    },
  ];

  const getButtonText = (key) => {
    if (key == "beacon") {
      return "Learn More @ Beacon";
    } else {
      return "Learn More";
    }
  };

  const getButtonLink = (key) => {
    if (key == "beacon") {
      return "https://beaconchannelmanagement.com";
    } else {
      return "/resources";
    }
  };

  const renderMessages = () => {
    return _.map(calloutMessages, (message) => {
      return (
        <div key={message.key}>
          <div className="callout">
            <div className="callout__indicator" />
            <div className="callout__message">{message.text}</div>
            <div className="callout__action">
              <a href={getButtonLink(message.key)}>
                <div className="btn btn--tiny btn--white color-blue">{getButtonText(message.key)}</div>
              </a>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="animated delay slow-1 fadeInDown">
      <Slider {...settings}>{renderMessages()}</Slider>
    </div>
  );
};

export default CallOut;
