const fieldDetails = [
  {
    name: "contracted_entity_id",
    title: "Contracted Entity ID",
    description:
      "The 340B ID of the covered entity where the prescription originated. This is usually the 340B ID of the covered entity under which you registered.",
    required: true,
    order: 0,
  },
  {
    name: "date_of_service",
    title: "Date of Service",
    description: "Date on which the patient filled their prescription.",
    required: true,
    order: 1,
  },
  {
    name: "date_prescribed",
    title: "Date Prescribed",
    description: "Date the prescriber wrote the prescription.",
    required: true,
    order: 2,
  },
  {
    name: "ndc",
    title: "NDC",
    description:
      "The 11-digit National Drug Code which indicates the manufacturer, product, and the commercial package size.",
    required: true,
    order: 3,
  },
  {
    name: "quantity",
    title: "Quantity",
    description: "The number of units in the prescription.",
    required: true,
    order: 4,
  },
  {
    name: "rx_number",
    title: "Rx Number",
    description: "The native (unmodified) prescription number for the prescription as generated by the pharmacy.",
    required: true,
    order: 5,
  },
  {
    name: "service_provider_id",
    title: "Service Provider ID",
    description:
      "A unique, public ID for the dispensing pharmacy. Accepted IDs include the NPI, DEA, NCPDP, and Medicaid ID.",
    required: true,
    order: 6,
  },
  {
    name: "service_provider_id_qualifier",
    title: "Service Provider ID Qualifier",
    description:
      "Indicates the type of unique ID provider. “01” for NPI, “05” for Medicaid”, “07” for NCPDP, and “12” for DEA.",
    required: false,
    required_for_bms: true,
    order: 7,
  },
  {
    name: "prescriber_id",
    title: "Prescriber ID",
    description: "A unique, public ID for the prescribing physician. Accepted IDs include the NPI and DEA ID.",
    required: false,
    required_for_bms: true,
    order: 8,
  },
  {
    name: "prescriber_id_qualifier",
    title: "Prescriber ID Qualifier",
    description: "Indicates the type of unique ID provided. A value of “01” indicates NPI, “12” indicates DEA.",
    required: false,
    required_for_bms: true,
    order: 9,
  },
  {
    name: "wholesaler_invoice_number",
    title: "Wholesaler Invoice Number",
    description:
      "The invoice number assigned by the wholesaler for the replenishment order made by the 340B covered entity.",
    required: false,
    required_for_bms: true,
    order: 10,
  },
  {
    name: "payer_bin",
    title: "Payer BIN",
    description: "The bank identification number of the primary payer on the prescription.",
    required: false,
    required_for_bms: true,
    order: 11,
  },
  {
    name: "payer_pcn",
    title: "Payer PCN",
    description:
      "Processor Control Number. Identifier used to determine which processor will handle a prescription drug claim.",
    required: false,
    required_for_bms: true,
    order: 12,
  },
  {
    name: "ship_to_location",
    title: "Ship to Location",
    description: "NPI, DEA, or NCPDP of the pharmacy where the drug was physically shipped.",
    required: false,
    required_for_bms: true,
    order: 13,
  },
  {
    name: "ship_to_date",
    title: "Ship to Date",
    description: "Date when the drug was shipped to the Ship To location.",
    required: false,
    required_for_bms: true,
    order: 14,
  },
  {
    name: "account_340b",
    title: "340B Account",
    description: "Account number assigned by the wholesaler and used for the purchase.",
    required: false,
    required_for_bms: true,
    order: 15,
  },
  {
    name: "product_serialization_number",
    title: "Product Serialization Number",
    description: "Unique ID assigned to the package shipped from the manufacturer to the wholesaler.",
    required: false,
    required_for_bms: true,
    order: 16,
  },
  {
    name: "fill_number",
    title: "Fill Number",
    description:
      "Indicates the number of times the prescription has been filled as of the current fill. For example, a value of 2 indicates that the prescription has been filled twice and the current fill is the second one.",
    required: false,
    required_for_bms: false,
    order: 17,
  },
];

const fieldsSchema = {
  date_of_service: null,
  date_prescribed: null,
  rx_number: null,
  ndc: null,
  quantity: null,
  prescriber_id: null,
  prescriber_id_qualifier: null,
  service_provider_id: null,
  service_provider_id_qualifier: null,
  wholesaler_invoice_number: null,
  contracted_entity_id: null,
  claim_conforms_flag: null,
  formatted_rx_number: null,
  payer_bin: null,
  payer_pcn: null,
  ship_to_location: null,
  ship_to_date: null,
  account_340b: null,
  product_serialization_number: null,
  fill_number: null,
};

const required = [
  "date_of_service",
  "date_prescribed",
  "rx_number",
  "ndc",
  "quantity",
  "service_provider_id",
  "contracted_entity_id",
];

const notRequired = [
  "prescriber_id",
  "prescriber_id_qualifier",
  "service_provider_id_qualifier",
  "wholesaler_invoice_number",
  "claim_conforms_flag",
  "formatted_rx_number",
  "payer_bin",
  "payer_pcn",
  "ship_to_date",
  "ship_to_location",
  "account_340b",
  "product_serialization_number",
  "fill_number",
];

const notRequiredForBms = ["fill_number"];

export const schema = () => {
  return fieldsSchema;
};

export const fields = () => {
  return fieldDetails;
};

export const fieldsNotRequired = () => {
  return notRequired;
};

export const fieldsNotRequiredForBms = () => {
  return notRequiredForBms;
};

export const requiredForBMS = fieldDetails.reduce((obj, column) => {
  obj[column.name] = column.required_for_bms;
  return obj;
}, {});

export const requiredForNonBMS = fieldDetails.reduce((obj, column) => {
  obj[column.name] = column.required;
  return obj;
}, {});

export const isRequiredFieldForBMS = (column) => {
  return requiredForBMS[column];
};
